var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Add Service" } }),
      _c(
        "gov-back-link",
        {
          attrs: {
            to: {
              name: "services-show",
              params: { service: _vm.$route.params.service }
            }
          }
        },
        [_vm._v("\n    Back to service\n  ")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "one-half" } },
                    [
                      _c("gov-heading", { attrs: { size: "xl" } }, [
                        _vm._v(
                          "Add a location to your " + _vm._s(_vm.service.type)
                        )
                      ]),
                      _c("gov-body", [
                        _vm._v(
                          "\n          Now that you've created your " +
                            _vm._s(_vm.service.type) +
                            ", you can add\n          locations where you " +
                            _vm._s(_vm.service.type) +
                            " operates at.\n        "
                        )
                      ]),
                      _c(
                        "gov-button",
                        {
                          attrs: {
                            to: {
                              name: "services-show",
                              params: { service: _vm.service.id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          Continue to " +
                              _vm._s(_vm.service.type) +
                              " "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "gov-button",
                        {
                          attrs: {
                            to: {
                              name: "service-locations-create",
                              params: { service: _vm.service.id }
                            },
                            success: ""
                          }
                        },
                        [_vm._v("\n          Add location\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }